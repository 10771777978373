<template>
  <div class="designManager">
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom" size="small" :inline="true" lable-width="80px"
               :model="design.searchForm">
        <el-form-item label="设计名称:">
          <el-input v-model="design.searchForm.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="设计ID:">
          <el-input v-model="design.searchForm.id" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="价格:">
          <el-col :span="11">
            <el-input v-model="design.searchForm.priceMin" placeholder="" @blur="blur1"></el-input>
          </el-col>
          <el-col class="sctp-tc" :span="2">~</el-col>
          <el-col :span="11">
            <el-input v-model="design.searchForm.priceMax" placeholder="" @blur="blur2"></el-input>
          </el-col>
        </el-form-item>
        <el-button size="small" class="sctp-square" @click="design.resetSearchForm">重置</el-button>
        <el-button size="small" type="primary" class="sctp-square" @click="design.loadData">搜索
        </el-button>
        <el-button @click="goPage('/shop/release/design')" class="sctp-square" type="primary"
                   size="small"
                   icon="el-icon-plus">发布设计
        </el-button>
      </el-form>
      <el-table
        :data="design.data"
      >
        <el-table-column
          label="基本信息">
          <template slot-scope="item">
            <div class="flex" style="flex-wrap: nowrap;">
              <div>#{{ item.row.productId }}</div>
              <div class="sctp-bold sctp-ellipsis sctp-mar-l10 flex-item1">{{
                  item.row.productName
                }}
              </div>
            </div>
            <div class="flex">
              <div class="sctp-mar-r10">
                <el-image
                  style="width: 65px; height: 65px"
                  :src="item.row.productLogo"
                  fit="contain">
                  <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                       style="height: 100%;">
                    <img src="../../../../../public/images/product/defaultbank.png" width="150px" height="150px">
                  </div>
                </el-image>
              </div>
              <div class="flex-item1 flex" style="overflow-x: auto;flex-wrap: nowrap;">
                <template v-if="item.row.previews">
                  <template v-for="image in item.row.previews">
                    <el-image
                      style="width: 65px; height: 65px;flex-shrink: 0;"
                      :src="image"
                      fit="contain">
                      <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                           style="height: 100%;">
                        暂无图片
                      </div>
                    </el-image>
                  </template>
                </template>
              </div>
            </div>
            <div>
              ({{ item.row.typeName }}-{{ item.row.subTypeName }})/
              <template v-if="item.row.devTools">{{ item.row.devTools + '/' }}</template>
              <template v-if="item.row.fileAmount">{{ item.row.fileAmount + '个/' }}</template>
              <template v-if="item.row.fileSize && item.row.sizeUnit">{{
                  item.row.fileSize + '' + item.row.sizeUnit + '/'
                }}
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="售价（元）">
          <template slot-scope="scope">
            <div class="sctp-flex sctp-flex-center sctp-flex-column">
              <div style="font-weight: bold;" class="sctp-font-16">{{
                  (scope.row.productPrice || 0).toMoney()
                }}
              </div>
              <div style="text-decoration: line-through;" class="sctp-info">{{
                  (scope.row.underlinedPrice || 0).toMoney()
                }}
              </div>
              <i
                @click="design.onOptionBtnClick('editPrice', scope.row, scope.$index)"
                class="el-icon-edit"></i>
            </div>
            <div class="sctp-tc" style="cursor:pointer;"
                 @click.prevent="dealModelConfig.showDialog(scope.row)">
              <el-tag size="mini">
                {{
                  [' ', '私下交易', '担保交易', '私下/担保'][scope.row.dealModel] || ' '
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="stock"
          width="100px"
          align="center"
          label="库存/已售">
          <template slot-scope="scope">
            <div>{{ scope.row.stock }}
              <i
                @click="design.onOptionBtnClick('editStock', scope.row, scope.$index)"
                class="el-icon-edit"></i>/ {{ scope.row.salesVolume }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="90px"
          label="是否上架">
          <template slot-scope="scope">
            <template v-if="scope.row.auditFlag !== 3">
              <el-tooltip
                v-if="scope.row.status === 1"
                class="item" effect="dark"
                :content="`上架截至日期${dateToString(scope.row.shelvesDeadline)}`"
                placement="top">
                <el-tag size="small" effect="dark">
                  已上架
                </el-tag>
              </el-tooltip>
              <el-tag size="small" type="info" effect="dark" v-if="scope.row.status === 0">
                未上架
              </el-tag>
            </template>
            <template v-else>
              <el-tag size="small" type="warning" effect="dark">
                强制下架
              </el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="审核结果">
          <template slot-scope="scope">
            <el-tag size="small" type="danger" effect="dark" v-if="scope.row.auditFlag === 0">
              未审核
            </el-tag>
            <el-tag size="small" effect="dark" v-if="scope.row.auditFlag === 1">
              已通过
            </el-tag>
            <template v-if="scope.row.auditFlag === 2 || scope.row.auditFlag === 3">
              <el-tooltip effect="dark" :content="scope.row.auditMemo" placement="top">
                <el-tag size="small" type="danger" effect="dark">
                  {{ scope.row.auditFlag === 2 ? '未通过' : '强制下架' }}
                </el-tag>
              </el-tooltip>
            </template>
            <el-tooltip class="item" effect="dark" content="点击查看审核历史" placement="top">
              <a
                @click="design.onOptionBtnClick('history', scope.row, scope.$index)"
              ><i class="el-icon-question"></i></a>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="最近更新">
          <template slot-scope="scope">
            {{ dateToString(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <div class="sctp-tc">
              <template v-if="scope.row.temporaryStorage">
                <div class="sctp-mar-b5">
                  <el-button
                    @click="goPage(`/shop/release/design/edit?designId=${scope.row.productId}`)"
                    size="mini"
                  >
                    继续完善
                  </el-button>
                </div>
              </template>
              <template v-else>
                <div class="sctp-mar-b5" v-if="scope.row.status === 0">
                  <el-button
                    size="mini"
                    @click="design.onOptionBtnClick('up', scope.row, scope.$index)"
                  >
                    上架
                  </el-button>
                </div>
                <div class="sctp-mar-b5" v-else>
                  <el-button
                    size="mini" framework
                    @click="design.onOptionBtnClick('down', scope.row, scope.$index)"
                  >
                    下架
                  </el-button>
                </div>
                <div class="sctp-mar-b5">
                  <el-button
                    @click="design.onOptionBtnClick('edit', scope.row, scope.$index)"
                    size="mini"
                  >
                    编辑
                  </el-button>
                </div>
                <div class="sctp-mar-b5">
                  <el-button
                    size="mini" type="primary"
                    @click="design.onOptionBtnClick('preview', scope.row, scope.$index)"
                  >
                    预览
                  </el-button>
                </div>
              </template>
              <div class="sctp-mar-b5">
                <el-button
                  size="mini" type="danger"
                  :disabled="scope.row.salesVolume > 0"
                  :title="scope.row.salesVolume ? '已售设计不能删除' : ''"
                  @click="design.onOptionBtnClick('del', scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="design.pageConfig.page"
          :page-size="design.pageConfig.limit"
          @size-change="design.pageConfig.handleSizeChange"
          @current-change="design.pageConfig.handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="design.pageConfig.total">
        </el-pagination>
      </div>
      <el-dialog width="400px" center title="选择交易方式" :visible.sync="dealModelConfig.show">
        <div style="height: 280px;">
          <el-radio-group v-model="dealModelConfig.value"
                          @change="dealModelConfig.onDealModelChange">
            <el-radio class="mg-b20" :label="1">双方私下交易</el-radio>
            <el-radio class="mg-b20" :label="2">平台担保交易</el-radio>
            <el-radio class="mg-b20" :label="3">双方私下交易/平台担保交易</el-radio>
          </el-radio-group>
        </div>
      </el-dialog>
      <el-dialog title="审核历史" class="auditHistory" :visible.sync="design.showHistory">
        <audit-history type="DESIGN" :id="activeDesignId"></audit-history>
      </el-dialog>
      <el-dialog width="600px" center title="文档上架需支付服务费" :visible.sync="payDialogConfig.showFlag">
        <pay-dialog type="DESIGN" @pay="payDialogConfig.onPay"></pay-dialog>
      </el-dialog>
      <el-dialog width="400px" center title="编辑价格" :visible.sync="updatePriceConfig.show">
        <update-price @success="updatePriceConfig.show = false;design.loadData()" type="DESIGN"
                      :model="updatePriceConfig.model" :id="activeDesignId"></update-price>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {design, goods} from "../../../../apis";

export default {
  name: "index",
  components: {
    AuditHistory: () => import('@PAGE/users-center-manage/AuditHistory.vue'),
    payDialog: () => import('@PAGE/users-center-manage/requirement-manage/RequirementOnlinePay'),
    UpdatePrice: () => import('@CMP/product/UpdatePrice'),
  },
  methods: {
    blur1() {
      if (this.design.searchForm.priceMax != null && this.design.searchForm.priceMin != null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.design.searchForm.priceMin))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.design.searchForm.priceMin=0
          return
        }
        let a = this.design.searchForm.priceMax * 1;
        let b = this.design.searchForm.priceMin * 1;
        if(b<0)
        {
          this.$alert('价格不能为负数')
          this.design.searchForm.priceMin=0
          return
        }
        if (a < b) {
          this.$alert("最低价格不能超过最高价格")
          this.design.searchForm.priceMin = null
        }
      }
    },
    blur2() {
      if (this.design.searchForm.priceMax != null && this.design.searchForm.priceMin != null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.design.searchForm.priceMax))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.design.searchForm.priceMax=100000000
          return
        }
        let a = this.design.searchForm.priceMax * 1;
        if(a<0)
        {
          this.$alert('价格不能为负数')
          this.design.searchForm.priceMax=1000000000
          return
        }
        let b = this.design.searchForm.priceMin * 1;
        if (a < b) {
          this.$alert("最高价格不能低于最低价格")
          this.design.searchForm.priceMax = 10000000
        }
      }
    },
  },
  data() {
    return {
      activeDesignId: null,
      updatePriceConfig: {
        show: false,
        model: {},
      },
      dealModelConfig: {
        id: null,
        value: null,
        show: false,
        showDialog(item) {
          this.show = true;
          this.value = item.dealModel;
          this.id = item.productId;
        },
        onDealModelChange: (label) => {
          goods.changeDealModel({
            productId: this.dealModelConfig.id,
            value: label,
            type: 3,
          }).then(res => {
            this.$message.success('修改成功');
            this.design.loadData();
          })
        }
      },
      design: {
        showHistory: false,
        onOptionBtnClick: (option, item, index) => {
          this.activeDesignId = item.productId;
          if (this.design.options[option]) {
            this.design.options[option].call(null, {item: item, index: index});
          }
        },
        options: {
          history: () => {
            this.design.showHistory = true;
          },
          preview: ({item, index}) => {
            this.openPage({
              path: `/design/view/${item.productId}`,
            });
          },
          editPrice: ({item, index}) => {
            this.updatePriceConfig.show = true;
            this.updatePriceConfig.model = {
              salePrice: item.productPrice,
              realPrice: item.underlinedPrice,
            }
          },
          editStock: ({item, index}) => {
            this.$prompt('请输入库存', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /^(?!0)(?:[0-9]{1,4}|10000)$/,
              inputErrorMessage: '库存限制范围 1 - 10000'
            }).then(({value}) => {
              goods.updateStock({
                id: item.productId,
                stock: value,
                type: 'DESIGN',
              }).then(res => {
                item.stock = value;
                this.$message.success('修改成功');
              })
            })
          },
          edit: ({item, index}) => {
            // let editPage = () => {
            //   this.goPage({
            //     path: `/shop/release/design/edit/${item.productId}`,
            //   });
            // };
            let editPage = () => {
              this.$router.push({
                path: '/shop/release/design/edit',
                query: {
                  designId: item.productId
                }
              })
            };
            if (item.sales_volume) {
              this.$confirm('已出售的商品无法编辑，请重新添加！', '提示', {
                type: 'warning',
                showCancelButton: false,
              })
              return;
            }
            if (item.auditFlag === 1) {
              this.$confirm(`${item.status === 1 ? '编辑需要先下架，' : ''}重新编辑后需要重新审核，确定编辑吗？`, '提示', {
                type: 'warning'
              }).then(() => {
                if (item.status === 1) {
                  this.design.doChangeStatus(item.productId, 0)
                    .then(() => {
                      editPage();
                    });
                } else {
                  editPage();
                }
              });
              return;
            }
            editPage();
          },
          del: ({item, index}) => {
            this.$confirm(`确认删除？`, '提示', {
              type: 'warning'
            }).then(() => {
              design.del({
                id: item.productId
              }).then(res => {
                this.$message.success('删除成功');
                this.design.loadData();
              });
            });
          },
          up: ({item, index}) => {
            if (item.auditFlag === 0 || goods.auditFlag === 2) {
              this.$confirm(`您的设计暂未通过审核，无法上架`, '提示', {
                type: 'warning'
              });
              return;
            }
            if (item.auditFlag === 3) {
              this.$confirm(`您的设计由于‘${item.auditMemo || ''}’被强制下架,请重新编辑`, '提示', {
                type: 'warning'
              }).then(() => {
                this.goPage({
                  path: `/shop/release/design/edit/${item.productId}`,
                });
              });
              return;
            }
            this.design.doChangeStatus(item.productId, 1).then(res => {
              this.$message.success('上架成功')
            });
          },
          down: ({item, index}) => {
            this.design.doChangeStatus(item.productId, 0).then(res => {
              this.$message.success('下架成功')
            });
          },
        },
        doChangeStatus: (id, status) => {
          return design.changeStatus({
            id: id,
            status: status,
            userId: this.user.userId
          }).then(res => {
            this.design.loadData();
          }).catch(res => {
            if (res && res.retCode === '9002') {
              this.payDialogConfig.showUpperShelvesDialog();
            }
            return Promise.reject(res);
          })
        },
        data: [],
        resetSearchForm: () => {
          Object.keys(this.design.searchForm).forEach(key => {
            this.design.searchForm[key] = null;
          })
        },
        loadData: () => {
          let {pageConfig: {page, limit}, searchForm} = this.design;
          design.list({
            page: page,
            limit: limit,
            userId: this.user.userId,
            id: searchForm.id,
            name: searchForm.name,
            priceMin: searchForm.priceMin,
            priceMax: searchForm.priceMax,
          }).then(res => {
            let {retdata, count = 0} = res;
            this.design.data = retdata;
            this.design.pageConfig.total = count;
          });
        },
        searchForm: {
          name: null,
          id: null,
          priceMin: null,
          priceMax: null,
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          handleSizeChange: (limit) => {
            this.design.pageConfig.limit = limit;
            this.design.pageConfig.page = 1;
            this.design.loadData();
          },
          handleCurrentChange: (page) => {
            this.design.pageConfig.page = page;
            this.design.loadData();
          }
        },
      },
      payDialogConfig: {
        showFlag: false,
        showUpperShelvesDialog: () => {
          this.payDialogConfig.showFlag = true
        },
        onPay: ({payWay, price, date}) => {
          return design.upperPay({
            id: this.activeDesignId,
            userId: this.user.userId,
            payWay: payWay,
            date: date
          }).then(res => {
            this.payDialogConfig.showFlag = false;
            this.$message.success('操作成功');
            this.design.loadData();
          });
        }
      },
    }
  },
  beforeMount() {
    this.design.loadData();
  }
}
</script>

<style scoped lang="scss">
.searchFrom ::v-deep .el-form-item__content {
  width: 130px !important;
}

.designManager {
  ::v-deep {
    td {
      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      :hover .el-icon-edit {
        display: inline-block;
      }
    }

    .el-dialog {
      width: 80%;

      .el-dialog__body {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
  }

  .auditHistory ::v-deep .el-dialog__body {
    padding-top: 0;

    & .el-table {
      font-size: 12px !important;
    }
  }

}

.searchFrom ::v-deep .el-input__inner {
  border-radius: 0;
}

.view_comment_dialog ::v-deep .el-dialog__body {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
